import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SiseContainer from "./components/SiseContainer";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <SiseContainer />
      <Footer />
    </div>
  );
}

export default App;
